import {GuidedToursProvider} from '@hconnect/uikit/src/lib2'
import React from 'react'

import {useRcfaTours} from '../../rcfa/guidedTours/rcfaTours'

export const MaintainToursProvider = ({children}: {children: React.ReactNode}) => {
  const {rcfaTours, enabledRcfaTours} = useRcfaTours()

  return (
    <GuidedToursProvider tours={rcfaTours} enabledTours={enabledRcfaTours}>
      {children}
    </GuidedToursProvider>
  )
}
