import {getPermissionsByUserId, getUserProfile} from '@hconnect/apiclient'
import {whiteListByPermission, Env} from '@hconnect/common/hproduce'
import {AppInsightsAdapter} from '@hconnect/common/logging'
import React from 'react'
import {createRoot} from 'react-dom/client'

import {App} from './App'
import {GlobalContextProvider, State} from './shared/hooks/useGlobalContext'
import {initLocalization} from './shared/localization'
import {AppProviders} from './shared/providers/AppProviders'
import {createApi} from './shared/providers/createApi'

// small helper
function render(contend: React.ReactNode) {
  const container = document.getElementById('root')
  const root = createRoot(container as HTMLElement)
  root.render(contend)
}

// activate the userTracking of Azure
AppInsightsAdapter.init()
AppInsightsAdapter.allowTracking()

// the core of the HProduce app
async function init() {
  const api = createApi()
  const {loginFlow} = api

  const [loginResponse] = await Promise.all([
    loginFlow.getLoginState(),
    initLocalization('default')
  ])

  if (!loginResponse.loggedIn) {
    await loginFlow.startLoginProcess()
    return
  }

  const [user, permissions] = await Promise.all([
    getUserProfile(api.axiosInstance)(loginResponse.decodedToken.user_id),
    getPermissionsByUserId(api.axiosInstance)(loginResponse.decodedToken.user_id)
  ])

  const whiteList = whiteListByPermission(process.env.REACT_APP_STAGE as Env, permissions)

  const initContextState: State = {
    whiteList,
    onLogout: () => void loginFlow.startLogoutProcess(),
    user: {
      email: loginResponse.decodedToken.email,
      name: user.name || user.username,
      userId: loginResponse.decodedToken.user_id,
      countryCode: loginResponse.decodedToken.country_code
    },
    permissions
  }

  render(
    <GlobalContextProvider initialState={initContextState}>
      <AppProviders axiosInstance={api.axiosInstance}>
        <App />
      </AppProviders>
    </GlobalContextProvider>
  )
}

init().catch((error) => {
  render(
    <div>
      <pre>{error?.toString?.()}</pre>
      <pre>{JSON.stringify(error, undefined, 2)}</pre>
    </div>
  )
})
