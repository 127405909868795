import {MaintainAppName} from '@hconnect/common/hproduce/maintain.types'
import {Localization} from '@hconnect/uikit'
import i18n from 'i18next'
import moment from 'moment-timezone'

const isDev = process.env.NODE_ENV === 'development'
async function loader(filename: string) {
  isDev && console.info(`Load Translation [${filename}]`)
  try {
    return (await import(`./${filename}`)).default
  } catch (err) {
    isDev && console.error(`Could not load Translation [${filename}]`, err)
    return {err}
  }
}

type SupportedLanguages = {
  [K in MaintainAppName]: string[]
}

export const supportedLanguages: SupportedLanguages = {
  Rcfa: ['en-US', 'de-DE', 'it-IT', 'ru-RU'],
  KMS: ['en-US', 'de-DE'],
  default: ['en-US', 'de-DE', 'it-IT', 'ru-RU'],
  Maintain: []
}

export async function initLocalization(appName: string) {
  i18n.on('languageChanged', (lng) => {
    // keep i18n and moment in sync
    moment.locale(lng)
  })

  return Localization({
    fallbackLng: 'en-US',
    supportedLngs: supportedLanguages[appName] ?? supportedLanguages.default,
    detection: {
      order: ['localStorage', 'cookie', 'navigator']
    },
    // when a translation is empty string "" this will result in using the fallback language translation
    returnEmptyString: false,
    backend: {
      loader
    }
  })
}
