import {PermissionType} from '@hconnect/apiclient'
import {waitForDOMElementToAppear} from '@hconnect/uikit/src'
import type {GuidedTour} from '@hconnect/uikit/src/lib2/components/guidedTour/types'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'

import {useGlobalContext} from '../../shared/hooks/useGlobalContext'
import {hasPermission} from '../hooks/usePermission'
import {routes} from '../routes'

import {ReactComponent as Illustration} from './Onboarding_illustration.svg'

type RcfaTour = 'stoppageFlowTour'

export const useRcfaTours = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const permissions = useGlobalContext().state.permissions

  const plantId = location.pathname?.split('/')?.[2]
  const canCreateAnalysis =
    permissions && plantId
      ? hasPermission(permissions, 'CREATE_ROOT_CAUSE_FAILURE_ANALYSES' as PermissionType, plantId)
      : false

  const enabledRcfaTours = useMemo(
    (): Record<RcfaTour, boolean> => ({
      stoppageFlowTour: location.pathname.includes('rcfa') && canCreateAnalysis
    }),
    [canCreateAnalysis, location.pathname]
  )

  const rcfaTours = useMemo(
    (): Record<RcfaTour, GuidedTour> => ({
      stoppageFlowTour: {
        title: t('rcfaTours.stoppageFlowTour.title'),
        description: t('rcfaTours.stoppageFlowTour.description'),
        onTourStarted: async () => {
          navigate(routes.analysisList.replace(':plantId', plantId))
          await waitForDOMElementToAppear('#analysis-add-button')
        },
        onTourCompleted: () => navigate(routes.analysisList.replace(':plantId', plantId)),
        onTourClosed: () => navigate(routes.analysisList.replace(':plantId', plantId)),
        image: <Illustration />,
        steps: [
          {
            target: '#analysis-add-button',
            title: t('rcfaTours.stoppageFlowTour.step1.title'),
            content: t('rcfaTours.stoppageFlowTour.step1.text'),
            placement: 'auto',
            disableScrolling: true
          },
          {
            target: '#cockpit-analysis-create-button',
            title: t('rcfaTours.stoppageFlowTour.step2.title'),
            content: t('rcfaTours.stoppageFlowTour.step2.text'),
            placement: 'auto',
            disableScrolling: true,
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear('#analysis-add-button')
              document.getElementById('analysis-add-button')?.click()
            },
            onGoPrevToStep: async () => {
              navigate(routes.analysisList.replace(':plantId', plantId))
              await waitForDOMElementToAppear('#analysis-add-button')
              document.getElementById('analysis-add-button')?.click()
            }
          },
          {
            target: '#stoppage-button-0',
            title: t('rcfaTours.stoppageFlowTour.step3.title'),
            content: t('rcfaTours.stoppageFlowTour.step3.text'),
            placement: 'auto',
            disableScrolling: true,
            onGoNextToStep: async () => {
              navigate(routes.stoppageList.replace(':plantId', plantId))
              await waitForDOMElementToAppear('#stoppage-button-0')
            },
            onGoPrevToStep: async () => {
              navigate(routes.stoppageList.replace(':plantId', plantId))
              await waitForDOMElementToAppear('#stoppage-button-0')
            }
          },
          {
            target: '#create-analysis-form',
            title: t('rcfaTours.stoppageFlowTour.step4.title'),
            content: t('rcfaTours.stoppageFlowTour.step4.text'),
            placement: 'right',
            onGoNextToStep: async () => {
              navigate(`${routes.newAnalysis.replace(':plantId', plantId)}?type=stoppage`, {
                state: {
                  selectedStoppage: {
                    id: 's_6102',
                    plantId: '0014',
                    stoppageCode: {
                      code: 'S917',
                      text: 'Mill - Process',
                      type: 'mill'
                    },
                    equipment: {
                      id: '02MM105',
                      text: 'Gummibandfoerderer  /Rohmehlband RM 1'
                    },
                    title: '02MM105 Lagerschaden',
                    description: 'Gurtförderer 02MM105 Umlenktrommel Lagerschaden',
                    start: '2023-04-08T12:00:00Z',
                    end: '2023-04-08T20:00:00Z',
                    duration: 28800,
                    createdBy: 'Thomas Wild',
                    createdById: '51164c38-1dae-4f70-bb51-d8401556b025',
                    updatedBy: 'Thomas Wild',
                    updatedById: '51164c38-1dae-4f70-bb51-d8401556b025',
                    createDate: '2023-04-08T19:04:15.778463Z',
                    updateDate: '2023-04-08T19:04:32.713941Z'
                  }
                }
              })
              await waitForDOMElementToAppear('#create-analysis-form')
            }
          },
          {
            target: '#create-analysis-save-button',
            title: t('rcfaTours.stoppageFlowTour.step5.title'),
            content: t('rcfaTours.stoppageFlowTour.step5.text'),
            placement: 'auto',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear('#create-analysis-save-button')
            }
          }
        ]
      }
    }),
    [navigate, plantId, t]
  )

  return {rcfaTours, enabledRcfaTours}
}
